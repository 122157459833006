<template>
  <div
    :class="[$style.avatar, { [$style.empty]: !src }, { [$style[size]]: size }]"
  >
    <img :class="$style.image" v-if="src" :src="src" alt="" />
    <div v-else :class="$style.placeholder"></div>
  </div>
</template>

<script setup>
const $props = defineProps({
  source: {
    /*
      {
        small: '',
        medium: '',
        ext: ''
      }
     */
    type: [Object, null],
    default: null
  },
  size: {
    type: String,
    validate: v =>
      ['large', 'medium', 'small', 'x-small', 'custom'].includes(v),
    default: 'large'
  }
})

const MapSizeSrc = {
  large: 'medium',
  medium: 'medium',
  small: 'small',
  'x-small': 'small',
  custom: 'medium'
}

const src = computed(() => $props.source?.[MapSizeSrc[$props.size]])
</script>

<style lang="scss" module>
.avatar {
  width: var(--width);
  height: var(--width);
  border-radius: 0.6em;
  padding: var(--border);
  background-color: #fff;
  flex-shrink: 0;

  .image,
  .placeholder {
    aspect-ratio: 1/1;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: inherit;
  }
  .placeholder {
    background-color: #41435c;
  }
}

.large {
  --width: 12em;
  --border: 3px;
  --after: 8em;
}

.medium {
  --width: 7.2em;
  --border: 2px;
  --after: 5.2em;
}

.small {
  --width: 6em;
  --border: 2px;
  --after: 4.6em;
}

.x-small {
  --width: 4.8em;
  --border: 2px;
  --after: 3.6em;
}

.empty {
  border-color: #41435c;
}
</style>
